<template>
  <div>
    <div v-if="$store.state.reports.length !== 0">
       <v-alert
           class="ma-3"
           :value="true"
           type="info"
           v-html="$tc('views.rapporten', $store.state.reports.length, {count: $store.state.reports.length, company: $store.state.company.name})"
      >
       </v-alert>

      <v-container>
        <v-row>
          <v-col class="d-flex flex-column" cols="12" md="4" v-for="(report, reportIndex) in $store.state.reports" :key="reportIndex">
            <v-card class="flex d-flex flex-column">
              <v-card-title primary-title>
                  {{ report.title }}
              </v-card-title>
              <v-card-text class="flex">
                <div v-html="report.description" />
              </v-card-text>
              <v-card-actions>
                <v-btn v-if="report.type === 'chapters'" text :to="$store.state.company.slug + '/' + report.slug + '/' + report.content[0].pages[0].slug"> Openen</v-btn>
                <v-btn v-if="report.type === 'pages'" text :to="$store.state.company.slug + '/' + report.slug + '/' + report.content[0].slug"> Openen</v-btn>
                <v-btn text :to="$store.state.company.slug + '/' + report.slug + '/print' ">Afdrukken</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-else>
      <v-alert :value="true" type="error">
        No reports found
      </v-alert>
    </div>
  </div>
</template>

<script>

export default {
  name: "Dashboard",
}
</script>

<style>
  .v-card__text, .v-card__title {
    word-break: normal !important;
  }
</style>
